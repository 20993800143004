@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  user-select: none;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination-bullet {
  color: #ffffff;
}

/*.swiper-slide img {*/
/*  display: block;*/
/*  width: calc(100% - 32px);*/
/*  height: 400px;*/
/*  object-fit: cover;*/
/*}*/

/*.landingSwiper .swiper-slide img {*/
/*  width: 100%;*/
/*  height: 56.25vw;*/
/*}*/

/*@media (max-width: 768px) {*/
/*  .swiper-slide img {*/
/*    width: calc(100% - 8px);*/
/*  }*/
/*}*/
